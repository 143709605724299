import { get } from 'lodash/object'
import CurrentOrganization from '~/modules/auth/Models/CurrentOrganization'
import Organization from '~/models/directories/Organization'

export default {
  refresh () {
    const currentOrganization = this.getCurrentOrganization()
    const orgId = get(currentOrganization, 'id', null)
    const organization = Organization.query().whereId(orgId).first()
    this.setCurrentOrganization(organization || currentOrganization)
  },

  getCurrentOrganization () {
    return CurrentOrganization.query().withAll().first()
  },

  setCurrentOrganization (organization) {
    if (!organization) {
      return CurrentOrganization.deleteAll()
    }
    return CurrentOrganization.create({
      data: organization
    })
  },

  reset () {
    CurrentOrganization.deleteAll()
  },

  // updateCurrentOrganization () {
  //   const user = authUser.get()
  //   const currentOrganization = this.getCurrentOrganization()
  //   if (!currentOrganization || !intersectionBy([user.ownedOrganization, ...this.getManagedOrganizations()], [currentOrganization], 'id').length) {
  //     this.setCurrentOrganization(this.getDefaultOrganization(user))
  //   } else {
  //     this.refresh()
  //   }
  // },

  getDefaultOrganizationId (user) {
    return user.currentOrganizationId
  }
}
