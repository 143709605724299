var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('v-form',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"form-title text-left mb-3"},[_vm._v(_vm._s(_vm.$t('Change access')))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12 py-0"}},[_c('p',{staticClass:"form-text text-left"},[_vm._v(_vm._s(_vm.$t('You can change role of this user'))+"\n\n"),_c('b',{staticClass:"form-text--highlight"},[_vm._v(_vm._s(_vm.user.name))]),_vm._v("\n\n"+_vm._s(_vm.$t('in the list of organizations or delete him there')))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.organizations,"hide-default-footer":"","hide-default-header":_vm.xsDevice},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(item.name))])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(_vm.roles[item.id] === _vm.model.ROLES.owner)?_c('tempalate',[_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t('Owner')))])]):[_c('ValidationProvider',{attrs:{"name":_vm.$t('Role'),"vid":_vm.roles[item.id],"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"value":_vm.roles[item.id],"items":_vm.userRoles,"outlined":"","required":"","hide-details":"","loading":_vm.isChangeRoleLoading,"menuProps":{ bottom: true, offsetY: true },"error-messages":errors,"disabled":_vm.roles[item.id] !== 'accountant' || _vm.roles[item.id] !== 'administrator'},on:{"change":function($event){return _vm.changeRole($event, item.id)}}})]}}],null,true)})]]}},(_vm.xsDevice)?{key:"item",fn:function(scopedItemProps){return [_c('block-manage-users-table-item-mobile',{attrs:{"scoped-item":scopedItemProps,"roles":_vm.userRoles},on:{"delete":function($event){return _vm.deleteUser(scopedItemProps.item.id)},"changeRole":_vm.saveChangedUserRoles}})]}}:null,{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('e-button-main',{attrs:{"plain":"","loading":_vm.isDeleteLoading},on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_c('e-svg-icon',{attrs:{"name":"trash"}})],1)]}}],null,true)})],1)],1),(_vm.xsDevice)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"main-button w-100",attrs:{"loading":_vm.isMobileChangeRoleLoading},on:{"click":_vm.mobileChangeRole}},[_vm._v(_vm._s(_vm.$t('Save')))])],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }