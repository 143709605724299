<template lang="pug">
  v-form(class="form-add-users")
    div(class="form-add-users__header")
      h2(class="form-add-users__title") {{ $t('Add users') }}
      p(class="form-add-users__subtitle") {{ $t('Enter your colleagues` email and assign them a role, and we`ll send a message telling them what to do next. You can always change access in the settings section') }}
    div(class="form-add-users__content")
      ValidationObserver(ref="form")
        div(
          v-for="(user, idx) in users"
          :key="idx"
          class="form-add-users__item"
        )
          ValidationProvider(:name="$t('Email')" rules="required|email" v-slot="{ errors }" class="w-50" mode="lazy")
            v-text-field(
              v-model="users[idx].email"
              :label="$t('Email')"
              :error-messages="errors"
              filled
            )
          ValidationProvider(:name="$t('Role in system')" rules="required" v-slot="{ errors }" class="w-50" mode="lazy")
            v-select(
              v-model="users[idx].role"
              :items="userRoles"
              :label="$t('Role in system')"
              :error-messages="errors"
              :menuProps="{ bottom: true, offsetY: true }"
              filled
            )
        e-link(@click="addUser") {{ $t('Add one more user') }}
    div(class="form-add-users__actions")
      v-btn(
        class="flex-grow-1"
        height="48"
        @click="closeModal(false)"
      ) {{ $t('Cancel') }}
      v-btn(
        :loading="isLoading"
        class="main-button flex-grow-1"
        height="48"
        @click="submit"
      ) {{ $t('Send') }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Props from '../../../../mixins/modules/dialogs/props'
import OrganizationUsers from '~/modules/users/models/OrganizationUsers'
import responsive from '~/mixins/pages/responsive'
import ELink from '~/components/elements/links/e-link'

export default {
  name: 'FormAddUsers',
  components: {
    Props,
    ValidationProvider,
    ELink
  },
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      users: [{
        email: null,
        role: null
      }],
      isLoading: false
    }
  },
  computed: {
    userRoles () {
      return OrganizationUsers.userRoles.filter(item => item.value !== OrganizationUsers.ROLES.OWNER)
    }
  },
  methods: {
    addUser () {
      this.users.push({
        email: null,
        role: null
      })
    },
    async submit () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.isLoading = true
        for (const { email, role } of this.users) {
          const payload = {
            email,
            role,
            organizationId: this.$route.params.id
          }
          await OrganizationUsers.api().create(payload)
        }
        this.closeModal(true)
      } catch (e) {
        this.closeModal(false)
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
.form-add-users {
  padding: 24px 0 28px 0;
  text-align: left;
  &__title {
    color: #000;
    font-size: 32px !important;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 8px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      font-size: 20px !important;
      line-height: 32px;
      margin-bottom: 0;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__subtitle {
    color: rgba(0, 0, 0, 0.80);
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 32px !important;
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-top: 32px;
  }
}
</style>
