<template lang="pug">
  ValidationProvider(:rules="rules" name="uploader" ref="uploader")
    v-btn(
      id="download"
      class="main-button w-100 mb-6"
      :loading="loading"
      @click="openFileMenu"
    ) {{ $t('Download') }}
    v-file-input(
      v-show="false"
      ref="file"
      type="file"
      multiple
      class="e-input-file"
      :accept="acceptedFileExtensions"
      @change="upload($event)"
    )
</template>

<script>
import Base64js from 'base64-js'
import converters from '~/mixins/methods/converters'
import Document from '~/modules/documents/models/Document'

export default {
  name: 'EButtonDocumentUpload',
  mixins: [converters],
  props: {
    gtmEventName: {
      type: String,
      default: 'click_download'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    validationAccept () {
      return 'jpg,jpeg,png,doc,docx,xls,xlsx,pdf,txt'
    },
    rules () {
      const rules = 'required|size:35000|ext:'

      return rules + this.validationAccept
    },
    extensionsMap () {
      return {
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        doc: 'application/msword',
        pdf: 'application/pdf',
        txt: 'text/plain',
        png: 'image/png',
        jpeg: 'image/jpeg',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xls: 'application/vnd.ms-excel'
      }
    },
    acceptedFileExtensions () {
      return Object.values(this.extensionsMap).join(',')
    }
  },
  methods: {
    openFileMenu () {
      window.dataLayer && window.dataLayer.push({
        event: this.gtmEventName,
        email: this._.get(this.$User, 'email')
      })
      this.$refs.file.$refs.input.click()
    },
    async upload (files) {
      const { valid, errors } = await this.$refs.uploader.validate(files)
      if (!valid) {
        const errMessage = this._.get(errors, '[0]')
        this.$notification.error(errMessage)
        return
      }
      try {
        this.loading = true
        for (const file of files) {
          const arrayBuffer = await this.blobToArrayBuffer(file)
          const uint8View = new Uint8Array(arrayBuffer)
          const base64Str = Base64js.fromByteArray(uint8View)
          try {
            const payload = {
              file: base64Str,
              filename: file?.name,
              title: file?.name.split('.').slice(0, -1).join('') // TODO: or just file.name ?
            }
            const documentId = this._.get(await Document.api().create(payload), 'response.data.id')

            if (files && files.length === 1) {
              await this.$router.push({ path: `/documents/${documentId}` })
              window.dataLayer && window.dataLayer.push({
                event: 'document_downloaded',
                email: this._.get(this.$User, 'email')
              })
              return
            }
            await Document.api().read(documentId)
            this.$notification.success(this.$t('{document} has been successfully uploaded', { document: file.name }))
          } catch (e) {
            const message = this._.get(e, 'response.data.message')
            this.$notification.error(this.$t('Error occurred with {document} - {error}', { document: file.name, error: message }))
          }
        }
        window.dataLayer && window.dataLayer.push({
          event: 'document_downloaded',
          email: this._.get(this.$User, 'email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
