import AuthToken from '~/modules/auth/Models/AuthToken'

export default function ({ route }) {
  const excludedRoutes = ['index', 'auth-login', 'open-id', 'show-id', 'bulk-signature-id']
  if (route && excludedRoutes.includes(route.name)) {
    return
  }
  const queryToken = route.query?.token
  const guestToken = AuthToken.query().where(token => token.userType === 'guest').first()
  if (queryToken) {
    AuthToken.insert({ data: { token: queryToken, userType: 'guest' } })
  } else if (!queryToken && guestToken) {
    AuthToken.delete(token => token.userType === 'guest')
  }
}
