<template lang="pug">
  v-form(class="pa-4")
    v-row
      v-col(cols="12")
        h1(class="form-title mb-3") {{ $t('Change role') }}
    v-row
      v-col(cols="12")
        ValidationObserver(ref="form")
          t-orm-fields(:items="fields" v-bind.sync="dataForm")
    v-row
      v-col(cols="6")
        v-btn(
          class="secondary-button plain-btn w-100"
          height="48"
          @click="closeModal(false)"
        ) {{ $t('Cancel') }}
      v-col(cols="6")
        v-btn(
          :loading="isLoading"
          class="main-button w-100"
          @click="submit"
        ) {{ $t('Change') }}
</template>

<script>
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import OrganizationUsers from '~/modules/users/models/OrganizationUsers'

export default {
  name: 'FormChangeUserRole',
  components: {
    TOrmFields
  },
  props: {
    closeModal: {
      type: Function,
      default: () => {}
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      dataForm: {
        email: '',
        role: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          model: 'email',
          component: 'v-text-field',
          provider: {
            name: 'Email',
            vid: 'email',
            rules: 'required'
          },
          attrs: {
            label: 'Email',
            filled: true,
            required: true
          }
        },
        {
          model: 'role',
          component: 'v-select',
          provider: {
            name: 'Users role',
            vid: 'role',
            rules: 'required'
          },
          items: () => OrganizationUsers.userRoles,
          attrs: {
            label: 'Users role',
            filled: true,
            required: true,
            menuProps: { bottom: true, offsetY: true },
            readonly: this.dataForm.role === OrganizationUsers.ROLES.OWNER
          }
        }
      ]
    }
  },
  created () {
    this.dataForm.email = this.user.email
    this.dataForm.role = this.user.role
  },
  methods: {
    async submit () {
      try {
        this.isLoading = true

        this._.get(await OrganizationUsers.api().update({ $id: this.user.id }, this.dataForm), 'response.data')
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss"></style>
