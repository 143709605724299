import routes from '~/const/routes'
import Organization from '~/models/directories/Organization'
import authUser from '~/modules/auth/authUser'

export const state = () => ({
  savedKeyData: {
    keyData: null
  }
})

export const getters = {
  savedKeyData: state => state.savedKeyData
}

export const mutations = {
  CHANGE_PASSWORD (state, value) {
    state.user = value
  },
  SET_KEY_DATA (state, payload) {
    state.savedKeyData = {
      ...state.savedKeyData,
      ...payload
    }
  }
}

export const actions = {
  organization ({ commit }, user) {
    return Organization.api().within(user, '/organization', { dataKey: null })
  },

  async updateProfile ({ commit, state, dispatch }, { user, organization = {}, updateOrganization, updateUser }) {
    // TODO: we do not have id in profile ? (maybe serialization problems).
    let res = null
    if (updateUser) {
      res = await this.$axios.put(routes.users.concrete(authUser.get().id), user)
    }
    if (updateOrganization) {
      await dispatch('updateOrganization', organization)
    }
    return res
  },

  async updateOrganization ({ state, dispatch }, organization) {
    if (organization && organization.id) {
      await this.$axios.put(routes.organization.concrete(organization.id), organization.payload)
      await Organization.api().read(organization.id)
      // await dispatch('organization', authUser.get())
    }
  }
}
