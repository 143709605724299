<template lang="pug">
  tr(class="v-data-table__mobile-row__tr")
    td(
      class="v-data-table__mobile-row__td"
    )
      v-menu(bottom offset-y content-class="w-100")
        template(v-slot:activator="{ on, attrs }")
          v-btn(
            v-on="on"
            v-bind="attrs"
            icon
            class="v-data-table__mobile-row__expand-btn"
            :class="{'v-data-table__mobile-row__expand-btn--active': scopedItem.isExpanded }"
            :disabled="scopedItem.item.role === 'owner'"
            @click="showRoles = !showRoles"
          )
            e-svg-icon(size="sm" name="arrow-down")
        v-list(v-if="showRoles")
          v-list-item(v-for="(role,idx) in roles" :key="idx" @click="changeRole(scopedItem.item.id, role)") {{ role.text }}
      div(class="text-left")
        div(class="manage-users-table-item-mobile__role") {{ userRole }}
        div(class="manage-users-table-item-mobile__name") {{ scopedItem.item.name }}
      v-btn(
        icon
        class="v-data-table__mobile-row__expand-btn"
        @click="$emit('delete')"
      )
        e-svg-icon(size="sm" name="trash")
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'

export default {
  components: {
    ESvgIcon
  },
  props: {
    scopedItem: {
      type: Object,
      default: () => ({})
    },
    roles: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      showRoles: false
    }
  },
  computed: {
    userRole () {
      if (!this.roles) {
        return
      }
      if (this.scopedItem.item.role === 'owner') {
        return this.$t('Owner')
      }
      const userRole = this.roles.find(roleItem => roleItem.value === this.scopedItem.item.role)
      return userRole.text
    }
  },
  methods: {
    changeRole (role) {
      this.$emit('changeRole', role, this.scopedItem.item)
    }
  }
}
</script>

<style scoped lang="scss">
.manage-users-table-item-mobile {
  &__role {
    color: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
  }
  &__name {
    color: #161B25;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
}
</style>
