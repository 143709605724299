import { get } from 'lodash/object'
import auth from '~/modules/auth/auth'
import authUser from '~/modules/auth/authUser'
import Organization from '~/models/directories/Organization'
import currentOrganizationService from '~/modules/auth/services/currentOrganization'
import CurrentOrganization from '~/modules/auth/Models/CurrentOrganization'
import User from '~/models/administration/User'

export default async function ({ route, from }, force = false, silent = true) {
  const excludedRoutes = ['index', 'auth-login', 'open-id', 'show-id', 'bulk-signature-id']
  if (route && excludedRoutes.includes(route.name)) {
    return
  }
  if (auth.getGuestAuthToken() && route.name === 'documents-id') {
    return
  }
  const user = authUser.get()
  if (!get(user, 'isSuperAdmin')) {
    try {
      const hasOrganization = Organization.exists()
      const hasCurrentOrganization = CurrentOrganization.exists()
      if (!hasCurrentOrganization) {
        if (!hasOrganization) {
          await Organization.api().all()
        }

        const savedOrganizationId = currentOrganizationService.getDefaultOrganizationId(user)
        if (savedOrganizationId) {
          const savedOrganization = Organization.query().whereId(savedOrganizationId).first()
          currentOrganizationService.setCurrentOrganization(savedOrganization)
        } else if (Organization.all().length === 1) {
          currentOrganizationService.setCurrentOrganization(Organization.query().first())
          await User.api().setCurrentOrganization({ organizationId: get(Organization.query().first(), 'id') })
        }
      }
    } catch (e) {
      if (!silent) { throw e }
    }
  }
}
