<template lang="pug">
    v-form(v-if="user" class="pa-4")
        v-row
            v-col(cols="12")
                h1(class="form-title text-left mb-3") {{ $t('Change access') }}
        v-row
            v-col(cols="12 py-0")
                p(class="form-text text-left")
                    | {{ $t('You can change role of this user') }}
                    |
                    |
                    b(class="form-text--highlight") {{ user.name }}
                    |
                    |
                    | {{ $t('in the list of organizations or delete him there') }}
        v-row
            v-col(cols="12")
                v-data-table(
                    :headers="headers"
                    :items="organizations"
                    hide-default-footer
                    :hide-default-header="xsDevice"
                )
                    template(#item.name="{ item }")
                      div(class="text-left") {{ item.name }}
                    template(#item.role="{ item }")
                        tempalate(v-if="roles[item.id] === model.ROLES.owner" )
                            div(class="text-left") {{ $t('Owner') }}
                        template(v-else)
                            ValidationProvider(
                                :name="$t('Role')"
                                :vid="roles[item.id]"
                                rules="required"
                                v-slot="{ errors }"
                            )
                                v-select(
                                    :value="roles[item.id]"
                                    @change="changeRole($event, item.id)"
                                    :items="userRoles"
                                    outlined
                                    required
                                    hide-details
                                    :loading="isChangeRoleLoading"
                                    :menuProps="{ bottom: true, offsetY: true }"
                                    :error-messages="errors"
                                    :disabled="roles[item.id] !== 'accountant' || roles[item.id] !== 'administrator'"
                                )
                    template(v-if="xsDevice" v-slot:item="scopedItemProps")
                        block-manage-users-table-item-mobile(
                          :scoped-item="scopedItemProps"
                          :roles="userRoles"
                          @delete="deleteUser(scopedItemProps.item.id)"
                          @changeRole="saveChangedUserRoles"
                        )
                    template(#item.delete="{ item }")
                        e-button-main(
                            plain
                            @click="deleteUser(item.id)"
                            :loading="isDeleteLoading"
                        )
                            e-svg-icon(name="trash")
        v-row(v-if="xsDevice")
            v-col(cols="12")
                v-btn(class="main-button w-100" @click="mobileChangeRole" :loading="isMobileChangeRoleLoading") {{ $t('Save') }}
</template>

<script>
import EButtonMain from '~/components/elements/buttons/e-button-main'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import OrganizationUsers from '~/modules/users/models/OrganizationUsers'
import TOrmFields from '~/components/templates/orm/t-orm-fields'
import BlockManageUsersTableItemMobile from '~/modules/users/views/components/block-manage-users-table-item-mobile'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'FormManageUser',
  components: {
    BlockManageUsersTableItemMobile,
    EButtonMain,
    ESvgIcon,
    TOrmFields
  },
  mixins: [responsive],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      roles: {},
      organizations: [],

      isChangeRoleLoading: false,
      isDeleteLoading: false,
      isMobileChangeRoleLoading: false,
      newRoles: {}
    }
  },
  computed: {
    model () {
      return OrganizationUsers
    },
    headers () {
      return [
        {
          text: 'Організація',
          value: 'name',
          width: '50%'
        },
        {
          text: 'Роль користувача',
          value: 'role',
          width: '45%'
        },
        {
          value: 'delete',
          width: '5%'
        }
      ]
    },
    userRoles () {
      return OrganizationUsers.userRoles
    }
  },
  created () {
    this.organizations = this._.cloneDeep(this.user.organizations)
    this.organizations.forEach(org => this.$set(this.roles, org.id, org.role))
  },
  methods: {
    async changeRole (newVal, orgId) {
      this.roles[orgId] = newVal
      try {
        this.isChangeRoleLoading = true

        await OrganizationUsers.api().update({ $id: this.user.id }, {
          email: this.user.email,
          role: this.roles[orgId]
        })
        this.$notification.success(this.$t('User role has been successfully changed'))
        this.$emit('changeUser')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isChangeRoleLoading = false
      }
    },
    async deleteUser (orgId) {
      try {
        this.isDeleteLoading = true

        await OrganizationUsers.api().del({ $id: this.user.id })
        this.$notification.success(this.$t('User has been successfully deleted'))
        this.$emit('changeUser')

        if (this.organizations.length === 1) {
          this.$attrs.closeModal()
        } else if (this.organizations.length > 1) {
          const deleteOrgIndex = this.organizations.findIndex(org => org.id === orgId)
          this.organizations.splice(deleteOrgIndex, 1)
        }
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.isDeleteLoading = false
      }
    },
    saveChangedUserRoles (organizationId, role) {
      this.$set(this.newRoles, organizationId, role)
    },
    mobileChangeRole () {
      this.isMobileChangeRoleLoading = true
      Object.entries(this.newRoles).forEach(async ([ordId, role]) => {
        await this.changeRole(role, ordId)
      })
      this.isMobileChangeRoleLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(7, 6, 0, 0.7);

  &--highlight {
    color: #000000;
  }
}
</style>
