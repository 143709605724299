<template lang="pug">
  div(class="profile-api-keys-copy")
    div(class="mb-8")
      v-text-field(
        :value="apiKey"
        readonly
        hide-details
        filled
        :label="$t('Generated API key')"
      )
    div(class="profile-api-keys-copy__actions")
      v-btn(
        height="48"
        @click="$emit('close')"
      ) {{ $t('Ready') }}
      v-btn(
        class="main-button"
        @click.stop="copyKey"
      ) {{ $t('Copy') }}
</template>

<script>
import clipboard from '~/mixins/global/_clipboard'
import successSnackbar from '~/modules/snackbar/mixins/successSnackbar'

export default {
  name: 'BlockProfileApiKeysCopy',
  mixins: [clipboard, successSnackbar],
  props: {
    apiKey: {
      type: String,
      default: null
    }
  },
  methods: {
    async copyKey () {
      try {
        await this.copy(this.apiKey)
        await this.successSnackbar.open({
          text: this.$t('Generated API key copied')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.profile-api-keys-copy {
  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
</style>
