<template lang="pug">
    v-form(class="pa-4")
        v-row
            v-col(cols="12")
                h1(class="form-title mb-3") {{ $t('User deletion') }}
        v-row
            v-col(cols="12 py-0")
                p(class="form-text") {{ $t('Invite') }} {{ userEmail }} {{ $t('will be canceled and it will be removed from the user list') }}
        v-row

        v-row
            v-col(cols="12" sm="6")
                v-btn(
                  class="secondary-button error-btn w-100"
                  height="48"
                  @click="confirm"
                ) {{ $t('Delete user') }}
            v-col(cols="12" sm="6")
                v-btn(
                  class="w-100 main-button"
                  height="48"
                  @click="close"
                ) {{ $t('Come back') }}
</template>

<script>
export default {
  name: 'FormConfirmUserDelete',
  props: {
    userEmail: {
      type: String,
      required: true
    }
  },
  methods: {
    close () {
      this.$attrs.closeModal(false)
    },
    confirm () {
      this.$attrs.closeModal(true)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
