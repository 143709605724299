<template lang="pug">
  v-snackbar(
    :value="isOpened"
    :timeout="timeout"
    color="#049541"
    rounded="pill"
    content-class="justify-start"
  )
    div(class="d-flex align-center")
      e-svg-icon(class="mr-4") copy-success-check
      | {{ text }}
</template>

<script>
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Snackbar from '~/modules/snackbar/models/Snackbar'

export default {
  name: 'SuccessSnackbar',
  components: { ESvgIcon },
  props: {
    type: {
      type: String,
      default: 'success'
    }
  },
  computed: {
    snackbar () {
      return Snackbar.query().where('type', this.type).first()
    },
    isOpened () {
      return this._.get(this.snackbar, 'isOpen', false)
    },
    params () {
      return this._.get(this.snackbar, 'params', {})
    },
    text () {
      return this._.get(this.params, 'text', '')
    },
    timeout () {
      return this._.get(this.params, 'timeout', 5000)
    }
  },
  created () {
    if (!this.snackbar) {
      Snackbar.insert({
        data: {
          type: this.type
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
